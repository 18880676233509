// NOTE: This file contains all the variables.

$body 	  		: #fafafa;
$theme-color	: #84ba3f;
$white			: #ffffff;
$black			: #000000;
$lightest-gray  : #f6f7f8;
$light-gray		: #626262;
$dark-gray		: #323232;
$sidebar-dark	: #282a39;
$border-color   : #eeeeee;
$dark-bg 		: #2f3243;
$lighter-gray	: #4e5165;

$primary  		:#007bff; 
$secondary 		:#6c757d; 
$success  		:#28a745; 
$danger  		:#dc3545; 
$warning  		:#ffc107; 
$info 	 		:#17a2b8; 
$light  		:#f8f9fa; 
$dark 	 		:#343a40; 
$muted 	 		:#6c757d; 

$font-xs		: 0.760rem; 
$font-s 		: 0.800rem; 
$font-base		: 1rem;	 
$font-md 		: 1.143rem; 	
$font-lg 		: 1.286rem; 
$font-xl		: 1.571rem;  
$font-xxl		: 1.714rem; 	
$font-xxxl		: 1.858rem; 

$line-xs		: 1rem;  			
$line-s			: 1.143rem;
$line-base		: 1.286rem;
$line-md        : 1.571rem;
$line-lg   		: 1.714rem;
$line-xl		: 1.857rem;
$line-xxl		: 2rem;
$line-xxxl		: 2.14rem;

$font-bold		: 700;
$font-semibold	: 600;
$font-medium 	: 500;
$font-regular	: 400;
$font-light		: 300;

$text-left		: left;
$text-center	: center;
$text-right		: right;

$facebook 		: #3b5998;
$twitter		: #00aced;
$linkedin		: #0077B5;
$dribbble		: #c73b65;
$googleplus		: #d34836;
$instagram		: #e2306a;
$pinterest		: #c92228;
$dropbox		: #3d9ae8;
$flickr			: #0063DC;
$tumblr			: #34526f;
$skype			: #12a5f4;
$youtube		: #c4302b;
$github			: #323232; 