// NOTE: This file contains typography SCSS.

html { 
	font-size: 14px;  
}

body { 
	font-family: 'Poppins', sans-serif; 
	margin: 0; 
	padding: 0; 
	font-size: 0.95rem; 
	color: $light-gray;
}

h1, h2, h3, h4, h5, h6, p, span, ul, li, a, ol {
	padding:0;
	margin:0;
}

h1, h2, h3, h4, h5, h6 {
	color: $dark-gray;
	font-weight: $font-medium;
	font-family: 'Poppins', sans-serif;
	margin-bottom: 0.5rem;
}
h1 {
	font-size: 2.558rem;
	line-height: 2.558rem;
}
h2 {
	font-size: 2.288rem;
	line-height: 2.288rem;
}

h3 {
	font-size: 1.858rem;
	line-height: 1.858rem;
}

h4 {
	font-size: 1.571rem;
	line-height: 1.858rem;
}

h5 {
	font-size: 1.286rem; 
	line-height: 1.858rem;
}
h6 {
	font-size: 1.143rem;
	line-height: 1.858rem;
}
a, a:hover, a:focus {
	color: inherit;
	text-decoration: none;
	outline: 0;
}
ul, ol {
	padding-left: 15px;
}

dl dt {
	font-weight: $font-medium;
}
dl dd {
	font-weight: $font-light;
}
.list-none {
	list-style: none;
}
svg {
	width: 100% !important;
}
 