/* You can add global styles to this file, and also import other style files */
/*
Template:  Webmin - Bootstrap 4 & Angular 5 Dashboard Template
Author: potenzaglobalsolutions.com
Design and Developed by: potenzaglobalsolutions.com

NOTE: This file includes all admin Template plugins and Shortcodes. If you don't want to use any plugins from below. You can simply remove that line from this file.
*/

/*
================================================
[  Table of contents  ]
================================================

1. Variables
2. Mixins
3. Helpers
4. Typography
5. Components
	- Accordion
	- Button
	- Lists
	- Nice Select
	- Pricing
	- Social Icon
	- Tabs
	- Owl Carousel
6. Sidebar
7. Layout
8. Responsive

======================================
[ End table content ]
======================================
*/



/*Global*/
// @import "~assets/scss/variables";


@import "./assets/scss/variables";
@import "./assets/scss/mixins";
@import "./assets/scss/helpers";
@import "./assets/scss/typography";

/*Shortcodes*/
@import "./assets/scss/components/accordion";
@import "./assets/scss/components/button";
@import "./assets/scss/components/list-style";
@import "./assets/scss/components/pricing";
@import "./assets/scss/components/social-icon";
@import "./assets/scss/components/tabs";
@import "./assets/scss/components/owl-carousel";

/*layout*/
@import "./assets/scss/sidebar";
@import "./assets/scss/layout";
@import "./assets/scss/responsive";
/* Fixes */

.card .btn-group .dropdown-menu {
	top:0;
}
.modal:before {
    content: '';
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,.5);
    top: 0;
    left: 0;
    right: 0;
}

.custom-day.range, .custom-day:hover, .ngb-dp-day .bg-primary {
    background: #84ba3f!important;
}
.calendar-main .month-name {
	margin-top:0.5rem;
}
.calendar-main .btn-success {
	background:#84ba3f;
	border-color:#84ba3f;
}
.calendar-main .btn-success:not(:disabled):not(.disabled):active, .calendar-main .btn-success:not(:disabled):not(.disabled).active {
	background-color: #70a52c;
    border-color: #70a52c;
}
.calendar-main .cal-month-view .cal-day-cell:not(:last-child), .cal-month-view .cal-days, .cal-month-view .cal-days .cal-cell-row {
	border-color:$border-color !important;
	color: $white !important;
}
.cal-month-view .cal-cell-row:hover, .cal-month-view .cal-cell-row:focus {
	background-color:rgba(0,0,0,0.1) !important;
}
.cal-month-view .cal-cell-row .cal-cell:hover, .cal-month-view .cal-cell.cal-has-events.cal-open {
	background-color:$body !important;
}
.cal-month-view .cal-day-cell.cal-weekend .cal-day-number {
	color:$white !important;
}
.cal-month-view .cal-open-day-events {
	background-color:$body !important;
	box-shadow:none !important;
}
.cal-month-view {
	background-color: transparent !important;
}
ngb-datepicker {
	border-color:$border-color !important;
	.ngb-dp-header {
		background:transparent !important;
		.ngb-dp-navigation-chevron {
			color:$muted !important;
		}
	}
	.ngb-dp-week {
		background:transparent !important;
	}
	.ngb-dp-weekday {
		color:$white !important;
	}
	.btn-light {
		color:$white;
	}
	&:focus {
		outline:0;
		box-shadow:none;
	}
	.ngb-dp-month-name {
		background:$lighter-gray !important;
	}
	&.dropdown-menu {
		background:$lighter-gray !important;
		border-color:$border-color !important;
	.ngb-dp-header {
		background:transparent !important;
		.ngb-dp-navigation-chevron {
			color:$muted !important;
		}
	}
	.ngb-dp-week {
		background:transparent !important;
	}
	.ngb-dp-weekday {
		color:$white !important;
	}
	.btn-light {
		color:$white;
	}
	&:focus {
		outline:0;
		box-shadow:none;
	}
	.ngb-dp-month-name {
		background:$lighter-gray !important;
	}
	.custom-select {
		border-color:$dark-gray;
	}
	}
}
hr {
	border-top: 1px solid $border-color;
}

.ngx-datatable.bootstrap .datatable-body .datatable-body-row, .ngx-datatable.bootstrap .datatable-header .datatable-header-cell {
	border-color:$border-color !important;
}
.ngx-datatable.bootstrap .datatable-footer {
	background:#424242 !important;
}

ngx-charts-line-chart text, ngx-charts-area-chart text, ngx-charts-bar-horizontal-2d text, ngx-charts-pie-chart text {
	fill:$white;
}
.ng-scrollbar-thumb {
	background-color: rgba($color: $white, $alpha: 0.15) !important;
}
.cal-month-view .cal-cell-top {
	outline: 0;
}

.multiselect-dropdown .dropdown-btn {
    line-height: 32px !important;
}
.dropdown-list {
    margin-top: 0px !important;
}

#toast-container > div {
	opacity:1;
}

@media (min-width: 576px) {
  .modal-xl {
    max-width: 90%;
  }
}

select.form-control{
	padding: 10px 15px 10px 20px;
}

.button.xx-small{
	padding:4px 12px;
	font-size: 0.9rem;
}

#toasta .toast {
  cursor: initial !important;
}
